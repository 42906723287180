import axios from "axios";
import { NIL } from "uuid";

//XXX Servidor Local
// const baseAddress = "http://172.31.125.86:5164/";
//XXX Desenvolvimento Seguro
const baseAddress = "https://desenv.api.yupschool.com/";
//XXX PRODUÇÃO
// const baseAddress = "https://api.yupschool.com/";
//XXX PRODUÇÃO Segundo Servidor
// const baseAddress = "https://course.api.yupschool.com/";

const urlCanAccess = baseAddress + "api/auth/can-access";//[POST]
const urlValidateAccess = baseAddress + "api/auth/validate-access";//[POST]
const urlRegisterEngagement = baseAddress + "api/academy/register-engagement";//[POST]

function postCanAccess(password, ok, erro) {
  axios.post(urlCanAccess, { password })
    .then((response) => ok(response))
    .catch((error) => erro(error));
}

function postValidateAccess(minutes, ok, erro) {
  axios.post(urlValidateAccess, { TokenExpirationInMinutes: minutes })
    .then((response) => ok(response))
    .catch((error) => erro(error));
}

function postRegisterEngagement({ jwt, id , contextId}, ok, erro) {
  const auth = "Bearer " + jwt
  axios.post(urlRegisterEngagement, { UserId: id, CourseVersionId: NIL, Context: contextId }, { headers: { Authorization: auth } })
    .then((response) => ok(response))
    .catch((error) => erro(error));
}

export { postCanAccess, postValidateAccess, postRegisterEngagement };
